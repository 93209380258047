<template>
  <b-nav class="wrap-border">
    <b-nav-item active>
      Overview
    </b-nav-item>
    <b-nav-item disabled>
      Teams
    </b-nav-item>
    <b-nav-item disabled>
      Users
    </b-nav-item>
    <b-nav-item @click="$router.push({ name: 'sales-lead-list'})">
      Sales Lead
    </b-nav-item>
    <b-nav-item disabled>
      Period Report
    </b-nav-item>
    <b-nav-item disabled>
      Final Report
    </b-nav-item>
    <b-nav-item disabled>
      Download
    </b-nav-item>
  </b-nav>
</template>

<script>
import { BNav, BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNav,
    BNavItem,
  },
}
</script>
